<template>
  <v-container fluid>
    <validation-observer ref="observer">
      <v-row>
        <v-col>
          <validation-observer ref="observer">
            <v-card outlined>
              <v-toolbar
                dense
                elevation="0"
                color="primary"
                class="secondary--text"
                dark
              >
                <v-toolbar-title>ORGANOGRAM</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent ref="form1">
                  <v-row justify="center" align="center">
                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-text-field
                          outlined
                          class="rounded-0"
                          label="Name"
                          v-model="office_name"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Division"
                        rules="required"
                      >
                        <v-select
                          outlined
                          class="rounded-0"
                          label="Division"
                          @change="changeDivision"
                          :items="getters_staff_divisions"
                          v-model="division"
                          item-text="division"
                          item-value="id"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Office Type"
                        rules="required"
                      >
                        <v-select
                          outlined
                          class="rounded-0"
                          label="Office Type"
                          :disabled="!division"
                          :items="office_types"
                          v-model="office_type"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Office ID"
                        rules="required"
                      >
                        <v-text-field
                          outlined
                          class="rounded-0"
                          label="Office ID"
                          v-model="office_id"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Reports To"
                        :rules="{
                          required_if: getters_organogram.length > 0,
                        }"
                      >
                        <v-autocomplete
                          outlined
                          :disabled="getters_organogram.length <= 0"
                          class="rounded-0"
                          label="Reports To"
                          :items="getters_organogram"
                          v-model="reports_to"
                          item-text="office_name"
                          item-value="id"
                          :error-messages="errors"
                          hide-details="auto"
                        >
                          <template #item="{ item }">
                            <span>
                              <b>
                                {{ `${item.office_type} of ` }}
                              </b>
                              {{ item.office_name }}
                            </span>
                          </template>
                          <template #selection="{ item }">
                            <span>
                              <b class="error--text">
                                {{ `${item.office_type} of ` }}
                              </b>
                              {{ item.office_name }}
                            </span>
                          </template>
                        </v-autocomplete>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Office Type"
                        rules="required"
                      >
                        <v-text-field
                          outlined
                          class="rounded-0"
                          label="Office Level"
                          type="number"
                          v-model.number="office_level"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <!-- <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Staff Category"
                        rules="required"
                      >
                        <v-select
                          outlined
                          class="rounded-0"
                          label="Classification"
                          :items="['Single', 'Multiple']"
                          v-model="classification"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-select>
                      </validation-provider>
                    </v-col> -->
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </validation-observer>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            color="success"
            :loading="loading"
            @click="sendData"
            class="font-weight-bold"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="getters_organogram"
          class="elevation-1"
          item-key="id"
          :loading="loading"
          hide-default-footer
        >
          <!-- <template #item.actions="{ item }">
            <v-menu
              offset-y
              open-on-hover
              transition="slide-x-transition"
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  outlined
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list
                dark
                color="primary darken-2"
                min-width="250"
                tile
                class="py-0"
              >
                <v-list-item-group>
                  <v-list-item link @click="editPositionBtn(item)">
                    <v-list-item-content>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item link @click="deletePositionBtn(item)">
                    <v-list-item-content>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { provide, reactive, toRefs } from "vue";
  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import {
    required,
    numeric,
    alpha,
    alpha_spaces,
    min_value,
    required_if,
  } from "vee-validate/dist/rules";

  extend("distinct", {
    params: ["target"],
    validate(value, { target }) {
      return parseInt(value) + parseInt(target) !== 100;
    },
    message: "Both Fields Should Add Up To 100%",
  });

  extend("required", {
    ...required,
    message: "{_field_} is required",
  });

  extend("required_if", {
    ...required_if,
    message: "{_field_} is required",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} is required",
  });

  extend("alpha", {
    ...alpha,
  });

  extend("numeric", {
    ...numeric,
  });

  extend("min_value", {
    ...min_value,
  });

  export default {
    components: {
      Response,
      ValidationProvider,
      ValidationObserver,
    },
    setup() {
      const { getStaffDivision, getOrganogram } = useActions([
        "getStaffDivision",
        "getOrganogram",
      ]);

      const { getters_staff_divisions, getters_organogram } = useGetters([
        "getters_staff_divisions",
        "getters_organogram",
      ]);

      const organogramForm = reactive({
        showComponent: false,
        componentCurrent: null,
        data: {},
        office_name: null,
        office_id: null,
        office_type: null,
        office_types: [],
        division: null,
        reports_to: null,
        office_level: null,
        classification: null,
        actionResponse: false,
        valid: false,
        observer: null,
        overlay: false,
        color: null,
        msgBody: null,
        msgIcon: null,
        msgHeader: null,
        loading: false,
        form1: null,
        id: 0,
        headers: [
          { text: "NAME", value: "office_name" },
          { text: "ID", value: "office_id" },
          { text: "TYPE", value: "office_type" },
          { text: "DIVISION", value: "staff_division.division" },
          { text: "REPORTS", value: "organogram.office_name" },
          { text: "LEVEL", value: "level" },
        ],
      });

      Promise.all([getStaffDivision(), getOrganogram()]).then(
        () => (loading.value = false)
      );

      const {
        showComponent,
        componentCurrent,
        form1,
        data,
        office_name,
        office_types,
        office_level,
        office_type,
        office_id,
        division,
        reports_to,
        observer,
        color,
        msgBody,
        msgIcon,
        actionResponse,
        msgHeader,
        loading,
        id,
        // classification,
      } = toRefs(organogramForm);

      const { saveOffice } = useActions(["saveOffice"]);

      const changeDivision = (id) => {
        let division = getters_staff_divisions.value.find(
          (item) => item.id == id
        );

        if (division.division == "Academic") {
          office_types.value = ["Faculty", "Department", "Office"];
          return;
        }
        office_types.value = ["Directorate", "Unit", "Office"];
      };

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            loading.value = true;

            saveOffice({
              id: id.value,
              office_name: office_name.value,
              organogram_id: reports_to.value,
              staff_division_id: division.value,
              office_type: office_type.value,
              office_level: office_level.value,
              office_id: office_id.value,
            })
              .then(() => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "success";
                msgBody.value = "Grade Is Successfully Saved.";
                msgIcon.value = "mdi-check-circle";
                msgHeader.value = "Saved";
              })
              .catch(() => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
              })
              .finally(() => {
                loading.value = false;
                form1.value.reset();
              });
          }
        });
      };

      provide("color", color);

      const editPositionBtn = (item) => {
        data.value = item;
        componentCurrent.value = "EditPosition";
        showComponent.value = true;
      };

      const deletePositionBtn = (item) => {
        data.value = item;
        componentCurrent.value = "DeletePosition";
        showComponent.value = true;
      };

      provide("office_name", data);

      return {
        ...toRefs(organogramForm),
        getters_staff_divisions,
        getters_organogram,
        sendData,
        editPositionBtn,
        deletePositionBtn,
        changeDivision,
      };
    },
  };
</script>
